// src/components/AdminNavBar.module.scss

.drawer {
    width: 250px;
    background-color: #2f3b52;
    color: #ffffff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
  }
  
  .drawerHeader {
    font-size: 1.5em;
    text-align: center;
    padding: 1em;
    color: #ffffff;
  }
  
  .navList {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
  }
  
  .navItem {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #445a75;
    }
  
    &.active {
      background-color: #1e2a3c;
    }
  }
  
  .icon {
    margin-right: 15px;
  }
  