.pageBackground {
    background: linear-gradient(135deg, #e3f2fd, #e0f7fa);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .container {
    position: relative;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    max-width: 400px;
    z-index: 1;
  }
  
  .heading {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .googleButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4285f4;
    color: white;
    border: none;
    padding: 0.8rem;
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: #357ae8;
    }
  }
  
  .googleIcon {
    margin-right: 0.5rem;
  }
  
  .divider {
    margin: 1rem 0;
    font-size: 0.9rem;
    color: #666;
    text-align: center;
  }
  
  .form {
    width: 100%;
  }
  
  .label {
    font-weight: 600;
    color: #333;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .input {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 0.5rem;
    font-size: 1rem;
  }
  
  .submitButton {
    background-color: #333;
    color: white;
    font-size: 1rem;
    padding: 0.8rem;
    width: 100%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: #555;
    }
  }
  
  .registerLink {
    margin-top: 1rem;
    text-align: center;
    font-size: 0.9rem;
    color: #555;
  
    span {
      color: #555;
    }
  
    .registerButton {
      color: #4285f4;
      font-weight: 600;
      text-decoration: none;
      transition: color 0.3s;
      cursor: pointer;
      &:hover {
        color: #357ae8;
      }
    }
  }
  
  .waveBackground {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="%23e3f2fd" d="M0,64L48,74.7C96,85,192,107,288,96C384,85,480,43,576,69.3C672,96,768,192,864,197.3C960,203,1056,117,1152,80C1248,43,1344,53,1392,58.7L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>');
    background-size: cover;
    z-index: -1;
  }
  