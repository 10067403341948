@import '../../variables.scss';

.footer_container {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 40px 20px;
  margin-top: $margin-large;
  overflow: hidden;
  position:sticky;
  width: calc(100% - 20px * 2);
  .footer_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    .footer_categories_container {
      flex: 1;

      div {
        margin-bottom: 20px;

        h6 {
          font-size: 1.2rem;
          color: #fff;
          margin-bottom: 10px;
          font-weight: 700;
          text-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        }

        a {
          display: block;
          font-size: 1rem;
          color: rgba(255, 255, 255, 0.8);
          margin-bottom: 8px;
          transition: color 0.3s ease;

          &:hover {
            color: #007bff;
          }
        }
      }
    }

    .footer_section {
      flex: 1;

      h3 {
        font-size: 1.5rem;
        color: #fff;
        margin-bottom: 15px;
        text-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      }

      p {
        font-size: 1rem;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 10px;
      }
    }
  }

  .footer_separation_line {
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    margin: 20px 0;
  }

  .footer_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .footer_socials {
      display: flex;
      gap: 15px;

      a {
        font-size: 1.5rem;
        color: rgba(255, 255, 255, 0.8);
        transition: color 0.3s ease;

        &:hover {
          color: #007bff;
        }
      }
    }

    .copyright {
      font-size: 1rem;
      color: black;
    }
  }

  .blurred_background {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(0, 123, 255, 0.3) 10%, rgba(255, 255, 255, 0) 70%);
    filter: blur(50px);
    z-index: -1;
    pointer-events: none;
  }
}
