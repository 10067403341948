// src/ContactUsPage.module.scss

.contactUsPage {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    background-color: #f4f4f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 150px;
    margin: 0px;

    @media screen and (max-width: 768px) {
        padding-top: 150px;
    }
}

.heroSection {
    text-align: center;
    margin-bottom: 4rem;

    @media screen and (max-width: 768px) {
        margin-bottom: 2rem;
    }
}

.heroTitle {
    font-size: 3rem;
    font-weight: 700;
    color: #333;
    letter-spacing: 2px;
    text-transform: uppercase;
    animation: fadeInDown 1s ease-out forwards;

    @media screen and (max-width: 768px) {
        font-size: 2rem;
    }
}

.heroSubtitle {
    font-size: 1.25rem;
    color: #666;
    margin-top: 1rem;
    animation: fadeInUp 1.5s ease-out forwards;

    @media screen and (max-width: 768px) {
        font-size: 1rem;
    }
}

.contactFormContainer {
    width: 100%;
    max-width: 600px;
    background-color: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    animation: fadeIn 2s ease-out forwards;

    @media screen and (max-width: 768px) {
        padding: 1rem;
    }
}

.backgroundAnimation {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    min-height: 200%;
    background: radial-gradient(circle at center, #007bff, #00c6ff, #007bff);
    animation: rotateBackground 15s linear infinite;
    z-index: -1;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes rotateBackground {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
