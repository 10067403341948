// src/Pages/Admin/SubscriptionListPage.module.scss

.subscriptionListPage {
    padding: 20px;
  }
  
  h1 {
    font-size: 1.8em;
    margin-bottom: 1em;
  }
  
  .subscriptionTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    font-size: 0.9em;
    color: #333;
  
    th, td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
  
    th {
      background-color: #f4f4f9;
      font-weight: bold;
    }
  
    tr:hover {
      background-color: #f1f1f1;
    }
  
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
  
      li {
        margin: 4px 0;
      }
    }
  }
  