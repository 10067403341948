.productsPage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pageTitle {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    margin-top: 150px;
    text-align: left;
    position: relative;
    color: #333; // Choose a color that matches your design

    // Text shadow effect
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);

    // Modern underline effect
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 50%;
        height: 4px;
        background-color: #4285f4; // Choose a highlight color for the underline
        border-radius: 2px;
        transition: width 0.3s ease;

        // Hover effect on underline
        .pageTitle:hover & {
            width: 100%;
        }
    }
}
.productCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    background-color: #ffffff;
}

.previewContainer {
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 1.5rem;
    
    &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }
}

.productPreview {
    width: 100%;
    height: 300px; /* Adjust height as needed */
    border: none;
}

.productTitle {
    font-size: 1.8rem;
    margin: 1rem 0;
    color: #333;
    text-align: center;
}

.productShortDescription {
    font-size: 1rem;
    color: #666;
    text-align: center;
    margin-bottom: 1.5rem;
}

.productFeatures {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
}

.featureItem {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: #333;
}

.featureIcon {
    margin-right: 0.5rem;
    font-size: 1.2rem;
}

.learnMoreButton {
    background-color: #333;
    color: #fff !important;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #555;
    }
}

.buyButton {
    margin-left: 10px;
    background-color: #4285f4;
    color: #fff;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #357ae8;
    }
}
