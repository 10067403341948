// PaymentFailure.module.scss

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #fff5f5;
    padding: 2rem;
    text-align: center;
  }
  
  .icon {
    font-size: 80px;
    color: #e53935;
    margin-bottom: 1.25rem;
  }
  
  .title {
    font-size: 2rem;
    color: #b71c1c;
    margin-bottom: 0.5rem;
  }
  
  .message {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  .timer {
    font-size: 0.9rem;
    color: #888;
  }
  