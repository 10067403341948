@import '../../../variables.scss';

.container {
  width: calc(100% - $margin-medium * 2);
  padding: $margin-medium;
  margin-top: $margin-large;
  margin-bottom: $margin-large;
  .title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1.5px;
    animation: slideDown 0.6s ease-out;
  }
}

.contactForm {
  max-width: 100%;
  padding: $margin-medium;
  margin: auto;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.8s ease-out;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
  }

  .formGroup {
    position: relative;
    margin-bottom: 20px;

    .input, .textarea {
      width: calc(100% - 15px * 2);
      border: 1px solid #ddd;
      border-radius: 10px;
      outline: none;
      transition: border-color 0.3s, box-shadow 0.3s;
      padding: 10px;
      font-size: 1rem;
      background-color: #f9f9f9;
      box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);

      &:focus {
        border-color: #007bff;
        box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
      }
    }

    .textarea {
      height: 150px;
      resize: none;
    }

    .label {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 0.8rem;
      color: #999;
      pointer-events: none;
      transition: transform 0.3s, font-size 0.3s, color 0.3s;

      &.active {
        transform: translateY(-150%);
        font-size: 0.85rem;
        color: #007bff;
      }
    }
  }

  .submitButton {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s ease;

    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1.2px;

    &:hover {
      background-color: #0056b3;
      transform: translateY(-2px);
    }

    &:active {
      background-color: #004a99;
      transform: translateY(0);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
