/* Primary Colors */
$primary-lighter-color: #335580;

$primary-color: #1a1a2e; // Dark Slate Blue
$accent-color: #e94560; // Fiery Red
$border-color: hsl(0, 0%, 88%); // Light Gray
$secondary-color: #4bc0c0; // Mint Green
$highlight-color: rgba(153, 102, 255, 1); // Soft Purple
$background-color: #ffffff; // Pure White


$focus-border-color: #e83e8c;


/* Neutral Colors */
$background-color: #F5F7FA;
$surface-color: #FFFFFF;
$text-primary-color: #333333;
$text-secondary-color: #666666;
$text-light-color: white;

/* Accent Colors */

$accent-color-1: #FF5A5F;
$accent-color-2: #F8E71C;

/* Additional Colors */
$success-color: #7ED321;
$info-color: #50BFE6;
$light-steel-blue: #A9B2C3;;

// Danger Color
$danger-color: #e74c3c;
$danger-color-hover: lighten($danger-color, 10%);
$danger-color-active: darken($danger-color, 10%);

// Font families
$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Merriweather', serif;

// Font sizes
$font-size-base: 1rem;
$font-size-title: 2.25rem; // Equivalent to 36px
$font-size-subtitle: 1.5rem; // Equivalent to 24px
$font-size-heading: 1.25rem; // Equivalent to 20px
$font-size-body: 1rem; // Equivalent to 16px
$font-size-small: 0.875rem; // Equivalent to 14px

// Font weights
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Margins
$margin-small: 8px;
$margin-medium: 16px;
$margin-large: 24px;
$margin-extra-large: 32px;

// Paddings
$padding-small: 8px;
$padding-medium: 16px;
$padding-large: 24px;
$padding-extra-large: 32px;

//height
$input-height: 30px;

//animation duration,
$animation-duration1: 0.3s;
$transition-duration: 0.3s;

//box shadow
$box-shadow1: 0 4px 8px hsla(0, 0%, 0%, 0.1);
$box-shadow-color: rgba(0, 0, 0, 0.1); /* Subtle shadow color */

a {
    text-decoration: none;
    color: inherit !important;
}