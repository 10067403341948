@import '../../../../variables.scss';

.header {
  position: fixed;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 0px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 30px * 2) ;
  overflow: hidden;
  .h1 {
    font-size: 1.8rem;
    color: #fff;
    font-weight: 700;
    margin: 0;
    text-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  .tab_desktop,
  .tab_mobile {
    display: flex;
    align-items: center;
  }

  .tab_desktop {
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .tab_mobile {
    justify-content: space-between;
    width: 100%;
    display: none;

    @media screen and (max-width: 768px) {
      display: flex;
    }
  }

  .hamburger {
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
  }

  .blurred_background {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 100%;
    height: 200%;
    overflow: hidden;
    background: radial-gradient(circle, rgba(0, 123, 255, 0.3) 10%, rgba(255, 255, 255, 0) 100%);
    filter: blur(50px);
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.h1 {
  animation: float 3s ease-in-out infinite;
}
.nav_item_underline {
    position: relative;
    display: inline-block;
    cursor: pointer;
    &:after {
        content: '';
        position: absolute;
        z-index: 10;
        width: 0;
        height: 2px;
        display: block;
        margin-top: 5px;
        left: 0px;
        background: white;
        transition: width 0.3s ease, left 0.3s ease;
    }
    &:hover::after {
        width: 100%;
        right: 0;
    }
}

.nav {
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-right: 20px;
    font-size: 1rem;

    &:last-child {
      margin-right: 0;
    }

    a {
      color: #fff;
      text-decoration: none;
      padding: 10px 15px;
      border-radius: 12px;
      transition: background 0.3s ease, transform 0.3s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
        transform: translateY(-3px);
      }
    }
  }
}

.user_options {
  display: flex;
  align-items: center;
  
  a {
    margin-left: 15px;
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    padding: 8px 12px;
    border-radius: 12px;
    transition: background 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.drawer {
  position:fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background-color: #fff;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 20000;
  display: flex;
  flex-direction: column;
  padding: 20px;

  &.open {
    transform: translateX(0);
  }
}

.drawer_header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .close_icon {
    font-size: 1.5em;
    cursor: pointer;
  }

  .drawer_title {
    margin: 0;
    font-size: 1.2em;
  }
}

.drawer_nav {
  margin-top: 20px;

  .drawer_nav_item {
    display: block;
    padding: 10px 0;
    color: #333;
    text-decoration: none;
    font-size: 1.1em;
    transition: color 0.3s;

    &:hover {
      color: #007bff;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1500;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.userButton {
  cursor: pointer;
}