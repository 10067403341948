.page {
    height:100vh;
    width: 100%;
    padding-top: 200px;
    position: relative;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    margin: auto;
    padding: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #ffffff;
  }
  
  .heading {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .googleButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4285f4;
    color: white;
    border: none;
    padding: 0.8rem;
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: #357ae8;
    }
  }
  
  .googleIcon {
    margin-right: 0.5rem;
  }
  
  .divider {
    margin: 1rem 0;
    font-size: 0.9rem;
    color: #666;
  }
  
  .form {
    width: 100%;
  }
  
  .label {
    font-weight: 600;
    color: #333;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .input {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 0.5rem;
    font-size: 1rem;
  }
  
  .passwordStrength {
    margin-top: 0.5rem;
    font-size: 0.85rem;
  }
  
  .weak {
    color: #ff4d4f;
  }
  
  .medium {
    color: #ffa500;
  }
  
  .strong {
    color: #4caf50;
  }
  
  .checkboxLabel {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: #555;
    margin-top: 1rem;
  }
  
  .checkbox {
    margin-right: 0.5rem;
  }
  
  .submitButton {
    background-color: #333;
    color: white;
    font-size: 1rem;
    padding: 0.8rem;
    width: 100%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
    &:hover {
      background-color: #555;
    }
  }
  
  .backgroundShapes {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -1;
  }
  
  .circle {
    position: absolute;
    border-radius: 50%;
    background: rgba(238, 243, 255, 1);
  }
  
  .circle1 {
    width: 200px;
    height: 200px;
    top: 20%;
    left: 10%;
  }
  
  .circle2 {
    width: 300px;
    height: 300px;
    bottom: 15%;
    right: 10%;
  }

  .loginLink {
    margin-top: 1rem;
    text-align: center;
    font-size: 0.9rem;
    color: #555;
  
    .loginButton {
      color: #4285f4;
      font-weight: 600;
      text-decoration: none;
      transition: color 0.3s;
      cursor: pointer;
      
      &:hover {
        color: darken(#357ae8, 10);
      }
    }
  }
  