// src/Pages/Admin/OrderListPage.module.scss

.orderListPage {
    padding: 20px;
  }
  
  h1 {
    font-size: 1.8em;
    margin-bottom: 1em;
  }
  
  .orderTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    font-size: 0.9em;
    color: #333;
  
    th, td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
  
    th {
      background-color: #f4f4f9;
      font-weight: bold;
    }
  
    tr:hover {
      background-color: #f1f1f1;
    }
  }
  