// SuccessPayment.module.scss

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 2rem;
    text-align: center;
  }
  
  .icon {
    font-size: 80px;
    color: #4caf50;
    margin-bottom: 1.25rem;
  }
  
  .title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .message {
    font-size: 1rem;
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  .button {
    background-color: #007bff;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #0056b3;
    }
  }
  