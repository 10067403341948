/* ForgotPassword.scss */
.pageResetPasswordBackground {
  background: linear-gradient(135deg, #e3f2fd, #e0f7fa);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.forgot_password_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  .request_container,
  .confirmation_container {
    text-align: center;
  }
  
  h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  p {
    color: #666;
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  input[type="email"] {
    width: calc(100% - 0.8rem * 2);
    padding: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .check_icon {
    font-size: 3rem;
    color: #28a745;
    margin-bottom: 1rem;
  }
  
  .resend_message {
    font-size: 1rem;
    color: #666;
  }
  
  .resend_link {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .resend_link:hover {
    color: #0056b3;
  }
  